'use strict';

(() => {
  const DOM = {};
  DOM.copyRightEl = document.querySelector('.footer-copy-right-year');

  const init = () => {
    const fullDate = new Date();
    DOM.copyRightEl.textContent = fullDate.getFullYear()
  }
  
  init();
  
})();
